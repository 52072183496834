import React, { useState } from "react";
import { SidebarItem } from "./SidebarItem";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { GetMenuIcon } from "./SidebarItem";
import { apiPost } from "../data/apidata";
import { useUserSettings } from "../components/UserSettingsProvider/UserSettingsProvider";

function getValFromObject(object) {
  try { return object.user.email; } catch (e) { return ""; }
}

function hasPermission(userObject, val) {
  try { return userObject.permissions.filter(p => (p.name === val)).length > 0 }
  catch (e) { return false }
}

export const MainContentLayout = ({ children }) => {

  const { userSettings, updateSettings } = useUserSettings();
  const [activeSubmenu, setActiveSubmenu] = useState('');
  const mail = getValFromObject(userSettings);
  const lists = getNavigationLists(hasPermission(userSettings, "admin_user_management"));
  const bottomList = {
    "Support": [],
    //    "Feedback" : []
  };
  function checkActiveSubmenu(label) {
    if (activeSubmenu === label)
      return true;
    else return false;
  }
  const DisplayMenu = ({ listConfig }) => {
    return (
      <>
        {
          Object.keys(listConfig).map((l, i) => {
            if (listConfig[l].length > 0) {
              return (
                <SubMenu

                  key={`submenu${i}${l}`}
                  icon={<GetMenuIcon val={l} />}
                  label={l}

                  onOpenChange={(e) => {
                    if (e === true)
                      setActiveSubmenu(l);

                    else
                      setActiveSubmenu('');
                    console.log(e)
                  }}
                  open={activeSubmenu === l}
                >
                  {
                    listConfig[l].map(s => {
                      return (<MenuItem
                        key={`submenu${i}${l}${s}`}
                        component={<Link to={`/${s}`} />}
                      >{s}</MenuItem>);
                    })
                  }
                </SubMenu>
              );
            }
            else {
              return (
                <MenuItem
                  icon={<GetMenuIcon val={l} />}
                  component={<Link to={`/${l}`} />}
                >{l}</MenuItem>
              );
            }

          })
        }
      </>
    );
  }


  return (

    <div className="maincontentlayout_container">
      <div className="maincontentlayout_sidebar">
        <br />
        <div
          className="sidebar_left_nav"
        >

          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                if (level === 0) {
                  return {
                    fontSize: "13px",
                    backgroundColor: '#dcf5f3',
                    //                fontWeight: "bold"
                  };
                }
                else {
                  return {
                    //fontSize:"1.4rem",
                    fontSize: "13px",
                    backgroundColor: "rgba(76, 193, 189, 0.1)",
                    //backgroundColor: active ? var(--primaryLight) : undefined,
                  };
                }
              },
            }}

          >

            <DisplayMenu listConfig={lists} />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <DisplayMenu listConfig={bottomList} />

          </Menu>



        </div>
      </div>
      <div className="maincontentlayout_main">
        {children}
      </div>
    </div>


  )
};

function getNavigationLists(isAdmin) {
  const lists = {
    "Dashboard": ["Live", "Reports", "Insights"],
    "People": ["Residents", "Teams"],
    "Configurations": ["Sense", "Move"],
    // "Configurations": ["Sense", "Move", 'Call'],
    "Move": [],
    "Monitoring": ["Battery Levels", "Heartbeat Monitoring"],
    "Settings": ["Device List", 'Groups', "Integrations", "Zone Profiles"],
  };


  if (isAdmin) {
    lists['Admin'] = ['User Management', 'Site Management']
    // lists['Admin'] = ['User Management', 'Site Management']
  }
  // lists["Admin"].push(["User Management"]);

  return lists;
}

