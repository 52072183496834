import React, { useEffect, useState } from 'react';
import { FormInput } from '../Input';
import './SitePage.css';
import { apiPost } from '../../data/apidata';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { Checkbox, Grid } from '@mui/material';
import DELETE_ICON from '../../images/settings/delete.png';

const SiteRoomConfig = ({ config, onClose, currStep, setCurrStep }) => {
    console.log(config)
    const [selection, setSelection] = useState([]);
    const [page_error, setPage_error] = useState('');
    const [toRoomUnit, setToRoomUnit] = useState(false)

    //Get everything room to Room Unit
    useEffect(() => {
        if (toRoomUnit === false) {
            let dd = config.devices;
            dd.map(item => {
                if (item.device_type.toLowerCase() === 'communal unit' ||
                    item.device_type.toLowerCase() === 'ensuite unit' ||
                    item.device_type.toLowerCase() === 'bedroom unit')
                    item['device_type'] = 'Room unit'
                return item;
            })

            config['devices'] = dd;

            let dt =
                config.device_types.filter(item =>
                    item.name.toLowerCase() !== 'communal unit' &&
                    item.name.toLowerCase() !== 'ensuite unit' &&
                    item.name.toLowerCase() !== 'bedroom unit'
                )
            config['device_types'] = dt;
            // console.log(dt, config)
            setToRoomUnit(true)
        }


    }, [config])


    const { enqueueSnackbar } = useSnackbar();
    // const [currStep, setCurrStep] = useState(2);
    return (
        <div className='new_user_page_container'><h1 style={{ marginBottom: '0px' }}>New Site</h1>
            <span className='formFieldError'>{page_error}</span>
            <br />
            {/* {currStep === 1 &&
                <Step1 config={config} onClose={onClose} setCurrStep={setCurrStep} />
            } */}
            {currStep === 4 &&
                <Step2 config={config} onClose={onClose} setCurrStep={setCurrStep}
                    page_error={page_error} setPage_error={setPage_error} />
            }
        </div>
    );
};
export { SiteRoomConfig };
const Step1 = ({ config, onClose, setCurrStep }) => {
    const [panels, setPanels] = useState(config['panels']);
    const [multiZone, setMultiZone] = useState('');


    function addDevice() {
        const tmp = [...panels, {
            device_name: '', device_type: '', unit_code: '', unit_zone: '',
        }]
        setPanels(tmp)
    }
    function removeDevice() {

        const tmp = panels.filter(item => item.isSelected === false)
        console.log('tmp', tmp)
        setPanels(tmp)
    }

    function handleApply() {
        const tmp = panels.map(item => {
            if (item['isSelected'] === true) {
                item['unit_zone'] = multiZone;
                item['isSelected'] = false;
            }
            return item;
        })
        setPanels(tmp)
    }
    function handleSave(e) {
        // console.log(panels.some(item => item.error == null || item.error === true), panels)
        if (panels.some(item => item.error == null || item.error === true) === true) return
        else {
            // console.log('here')
            const tmp = panels.map(item => {
                if (item.device_name.toLowerCase() === 'main panel')
                    item['email'] = `${config.site_code}@arquella.co.uk`;
                else
                    item['email'] = `${config.site_code}${item.device_name.replace(/ /g, '')}@arquella.co.uk`;

                item['panel_ref'] = `${config.site_code} ${item.device_name}`;
                return item;
            })
            setPanels(tmp)
            config['panels'] = tmp;
            console.log('config', config)
            setCurrStep(2)
        }

    }

    return (
        <div className='aqForm'>
            <div className='aq_fields' >
                <h4 style={{ marginTop: 0 }}>Setp 1/2 Panel config</h4>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '40px', marginBottom: '0px' }} >
                    <p className='unit_header_field' >Panel ref</p>
                    <p className='unit_header_field'>Zone</p>
                    <p className='unit_header_field'>Device type</p>
                </div>
                {
                    panels.map((item, i) => {
                        return (
                            <PanelEdit unitID={i}
                                unitName={item.device_name} unitZone={item.unit_zone}
                                unitDeviceType={item.device_type}
                                unitList={panels} setUnitList={setPanels}
                                zones={config['zones']}
                                deviceTypes={config['device_types']}
                                isSelected={item.isSelected}
                                error={item['error']} />
                        )
                    })
                }
                {panels.length > 0 &&
                    <button className="validate_button" onClick={addDevice}>Add Panel</button>
                }

            </div>
            <div className=' bottom_field' style={{ width: '100%', }} >

                <div>{panels.filter(item => item['isSelected'] === true).length} items selected</div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', width: '100%', }}>
                    {panels.filter(item => item['isSelected'] === true).length > 0 ?
                        <div style={{ alignSelf: 'flex-start' }}>
                            <select name='device_type' onChange={(e) => setMultiZone(e.target.value)} className='unit_edit_field' >
                                <option hidden selected>Select zone</option >
                                {config['zones'] != null && config['zones'].map(item => {
                                    return (<option >{item}</option>)
                                })}
                            </select>
                            <button className="validate_button btn_size" onClick={handleApply}> Apply</button>or
                            <button className="delete_button btn_size" onClick={removeDevice}> Delete</button>
                        </div>
                        : <div></div>
                    }
                    <div className='validate_close_div' style={{ alignSelf: 'flex-end' }}>
                        <button className='validate_button btn_size' style={{ marginRight: '2px' }} onClick={handleSave}>Next</button>
                        {/* <button className='validate_button' onClick={saveData}>Next step</button> */}
                        <button className='close_buton btn_size' onClick={onClose}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
const Step2 = ({ config, onClose, setCurrStep, page_error, setPage_error }) => {

    const [unitList, setUnitList] = useState(config['devices']);
    const [multiZone, setMultiZone] = useState('');

    function addDevice() {
        const tmp = [...unitList, {
            device_name: '', device_type: '', unit_code: '', unit_zone: '',
        }]
        setUnitList(tmp)
    }
    function removeDevice() {

        const tmp = unitList.filter(item => item.isSelected === false)
        console.log('tmp', tmp)
        setUnitList(tmp)
    }
    function handleApply() {
        const tmp = unitList.map(item => {
            if (item['isSelected'] === true) {
                item['unit_zone'] = multiZone;
                item['isSelected'] = false;
            }
            return item;
        })
        setUnitList(tmp)
    }
    const findNonUniqueNameAndId = () => {
        const idSet = new Set();
        const nameSet = new Set();

        const nonUniqueIds = [];
        let arr = config['devices']

        for (let item of arr) {
            const name = item.device_name;
            const id = item.unit_code;
            if (nameSet.has(name) || idSet.has(id)) {
                console.log(name, id)
                nonUniqueIds.push(item);
                item['error'] = true
            } else {
                idSet.add(id);
                nameSet.add(name);
            }
        }
        console.log(idSet, nameSet);
        config['devices'] = arr
        if (nonUniqueIds.length > 0)
            return false;
        else return true
    };
    function handleSave(e) {
        // console.log(panels.some(item => item.error == null || item.error === true), panels)

        console.log('config', config)
        if (unitList.some(item => item.error == null || item.error === true) === true)
            setPage_error('Make sure all devices are configured')
        else {
            const res = !findNonUniqueNameAndId()
            console.log(res)
            if (res) {
                setPage_error('Some devices have non unique Device name or unitID')

            }
            else {
                setPage_error('')


                const tmp = config['panels'].map(item => {
                    if (item.device_name.toLowerCase() === 'main panel')
                        item['email'] = `${config.site_code}@arquella.co.uk`;
                    else
                        item['email'] = `${config.site_code}${item.device_name.replace(/ /g, '')}@arquella.co.uk`;

                    item['panel_ref'] = `${config.site_code}${item.device_name}Config`;
                    return item;
                })
                config['panels'] = tmp;
                //Create site and get id
                apiPost(
                    'api/messages/site/management/addNewSite',
                    config.formData,
                    (d) => {
                        console.log('site id', d['message']['data'][0][0]);
                        console.log('new id', d['message']['data'][0][0].site_id)

                        config['site_id'] = d['message']['data'][0][0].site_id;
                        // config['dbPath'] = 'https://arquella-cc76e-2f53f-dev.firebaseio.com';
                        config['dbPath'] = d['message']['data'][0][0].db_path;

                        //Set device configs

                        let dd = config.devices;
                        dd.map(item => {
                            if (item.device_type.toLowerCase() === 'communal unit' ||
                                item.device_type.toLowerCase() === 'ensuite unit' ||
                                item.device_type.toLowerCase() === 'bedroom unit')
                                item['device_type'] = 'Room unit'
                            return item;
                        })
                        config['devices'] = dd;
                        apiPost('api/messages/site/management/SetSiteConfig',
                            config,
                            (d) => {
                                console.log(d)
                                enqueueSnackbar("Site created successfully", { variant: "success" });
                                onClose();
                            },
                            (e) => {
                                console.log(e)
                                enqueueSnackbar("Site created failed", { variant: "error" });

                            }
                        )
                    },
                    (e) => {
                        console.log(e);
                    }
                )
            }
        }
    }
    return (
        <div className='aqForm'>
            <div className='aq_fields' >
                <h4 style={{ marginTop: 0 }}>Setp 4/4 Device config</h4>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '40px', marginBottom: '0px' }} >
                    <p className='unit_header_field' >Device name</p>
                    <p className='unit_header_field' style={{ width: '50px' }}>UnitID</p>
                    <p className='unit_header_field'>Zone</p>
                    <p className='unit_header_field'>Device type</p>
                </div>
                {
                    unitList.map((item, i) => {
                        return (
                            <UnitEdit unitID={i} unitCode={item.unit_code}
                                unitName={item.device_name} unitZone={item.unit_zone}
                                unitDeviceType={item.device_type}
                                unitList={unitList} setUnitList={setUnitList}
                                zones={config['zones']}
                                deviceTypes={config['device_types']}
                                isSelected={item.isSelected}
                                error={item['error']}
                                setPage_error={setPage_error} />
                        )
                    })
                }
                {unitList.length > 0 &&
                    <button className="validate_button" onClick={addDevice}>Add Device</button>
                }

            </div>
            <div className=' bottom_field' style={{ width: '100%', }} >

                <div>{unitList.filter(item => item['isSelected'] === true).length} items selected</div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', width: '100%', }}>
                    {unitList.filter(item => item['isSelected'] === true).length > 0 ?
                        <div style={{ alignSelf: 'flex-start' }}>
                            <select name='device_type' onChange={(e) => setMultiZone(e.target.value)} className='unit_edit_field' >
                                <option hidden selected>Select zone</option >
                                {config['zones'] != null && config['zones'].map(item => {
                                    return (<option >{item}</option>)
                                })}
                            </select>
                            <button className="validate_button btn_size" onClick={handleApply}> Apply</button>or
                            <button className="delete_button btn_size" onClick={removeDevice}> Delete</button>
                        </div>
                        : <div></div>
                    }
                    <div className='validate_close_div' style={{ alignSelf: 'flex-end' }}>
                        <button className='validate_button btn_size' style={{ marginRight: '2px' }} onClick={(e) => setCurrStep(3)}>Previous</button>
                        <button className='validate_button btn_size' style={{ marginRight: '2px' }} onClick={handleSave}>Save</button>
                        {/* <button className='validate_button' onClick={saveData}>Next step</button> */}
                        <button className='close_buton btn_size' onClick={onClose}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
const UnitEdit = ({ unitID, unitCode, unitName, unitZone, unitDeviceType,
    unitList, setUnitList, zones, deviceTypes, isSelected, error, setPage_error }) => {
    const [checked, setChecked] = useState(isSelected);
    const [hasError, setHasError] = useState(error);
    const [divBg, setDivBg] = useState('div_default ');
    useEffect(() => {
        if (error !== hasError)
            setHasError(error)
    }, [error])

    useEffect(() => {
        setChecked(isSelected)
        let tmp = 'div_default '
        if (hasError) tmp += 'div_error '
        if (isSelected) tmp += 'div_notSelected '
        setDivBg(tmp)
    }, [isSelected, hasError])
    useEffect(() => {
        let res = false;
        if (unitName != null && unitZone != null && unitDeviceType != null && unitCode != null) {
            if (unitName.trim() !== '' && unitZone.trim() !== '' &&
                unitDeviceType.trim() !== '' && unitCode.trim() !== ''
                && unitCode.length === 3)
                res = false;
            else
                res = true
        }
        else
            res = true


        const tmp = unitList.map((item, i) => {
            if (i === unitID) {
                item['error'] = res;

            }
            return item;
        })
        setUnitList(tmp)
        // setHasError(res)
    }, [unitName, unitZone, unitDeviceType, unitCode])

    function handleUnitChange(e) {
        const tmp = unitList.map((item, i) => {
            if (i === unitID) {
                console.log(e.target.name)
                item[e.target.name] = e.target.value;
                item['isSelected'] = isSelected;
            }
            return item;
        })
        setUnitList(tmp);
    }
    function handleSelection(e) {
        // setChecked(e.target.checked);
        const tmp = unitList.map((item, i) => {
            if (i === unitID) {
                console.log(e.target.name);
                item['isSelected'] = e.target.checked;
            }
            return item;
        })
        setUnitList(tmp);
    }
    return (
        <div className={divBg} style={{ display: 'flex', alignItems: 'center' }}>
            <input type="checkbox" className="checkBox" checked={checked} onChange={handleSelection} />
            <input name='device_name' className='unit_edit_field' type="text" value={unitName} onChange={handleUnitChange} />

            <input name='unit_code' className='unit_edit_field' maxLength={3} style={{ width: '45px' }} type="text" value={unitCode} onChange={handleUnitChange} />

            <select name='unit_zone' placeholder='Select zone' className='unit_edit_field' onChange={handleUnitChange} >
                <option hidden selected>Select zone</option>
                {zones != null && zones.map(item => {
                    return (<option selected={item.toLowerCase().trim() === unitZone.toLowerCase().trim()}>{item}</option>)
                })}

            </select>

            <select name='device_type' className='unit_edit_field' onChange={handleUnitChange} >
                <option hidden selected>Select device</option >
                {deviceTypes != null && deviceTypes.map(item => {
                    return (<option selected={item.name === unitDeviceType}>{item.name}</option>)
                })}

            </select>

            {/* <button className="delete_button"><img src={DELETE_ICON} /></button> */}

        </div>
    )
}
const PanelEdit = ({ unitID, unitName, unitZone, unitDeviceType,
    unitList, setUnitList, zones, deviceTypes, isSelected, error }) => {
    const [checked, setChecked] = useState(isSelected);
    const [hasError, setHasError] = useState(false);
    const [divBg, setDivBg] = useState('div_default ');

    useEffect(() => {
        setChecked(isSelected)
        let tmp = 'div_default '
        if (hasError) tmp += 'div_error '
        if (isSelected) tmp += 'div_notSelected '
        setDivBg(tmp)
    }, [isSelected, hasError])

    useEffect(() => {
        let res = false;
        if (unitName != null && unitZone != null && unitDeviceType != null) {
            if (unitName.trim() !== '' && unitZone.trim() !== '' && unitDeviceType.trim() !== '')
                res = false;
            else
                res = true;
        }
        else
            res = true

        const tmp = unitList.map((item, i) => {
            if (i === unitID) {
                item['error'] = res;
                // console.log(item['error'])
            }
            return item;
        })
        setUnitList(tmp)
        setHasError(res)
        // console.log(unitName, unitZone, unitDeviceType)
    }, [unitName, unitZone, unitDeviceType])
    // useEffect(() => {
    //     if (error)
    //         setHasError(true);
    //     else setHasError(false);
    // }, [error])

    function handleUnitChange(e) {
        const tmp = unitList.map((item, i) => {
            if (i === unitID) {
                // console.log(e.target.name)
                item[e.target.name] = e.target.value;
                item['isSelected'] = isSelected;
            }
            return item;
        })
        setUnitList(tmp);
    }
    function handleSelection(e) {
        // setIsSelected(e.target.checked);
        const tmp = unitList.map((item, i) => {
            if (i === unitID) {
                console.log(e.target.name);
                item['isSelected'] = e.target.checked;
            }
            return item;
        })
        setUnitList(tmp);
    }
    return (
        // <div className={!isSelected ? "div_selected div_default" : 'div_notSelected div_default'} style={{ display: 'flex', alignItems: 'center' }}>
        <div className={divBg} style={{ display: 'flex', alignItems: 'center' }}>
            <input type="checkbox" className="checkBox" checked={checked} onChange={handleSelection} />
            <input name='device_name' className='unit_edit_field' type="text" value={unitName} onChange={handleUnitChange} />

            <select name='unit_zone' placeholder='Select zone' className='unit_edit_field' onChange={handleUnitChange} >
                <option hidden selected>Select zone</option>
                {zones != null && zones.map(item => {
                    return (<option selected={item === unitZone}>{item}</option>)
                })}

            </select>

            <select name='device_type' className='unit_edit_field' onChange={handleUnitChange} >
                <option selected hidden>Select device</option >
                <option selected={unitDeviceType === 'Panel'}>Panel</option >


            </select>

            {/* <button className="delete_button"><img src={DELETE_ICON} /></button> */}

        </div>
    )
}
