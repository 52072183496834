import * as XLSX from 'xlsx';
import { useModal } from '../../components/ModalContext/ModalContext';
import { useState } from 'react';
import { displayDateTimeFromUTC, displayDateTimeFromUTCExport, displayDateTimeDisplayFromUTC } from '../../functions/dataHandler';
import { groupBy } from '../../functions/objects_arrays';
import { getRoomRowValue, getZoneRowValue } from '../../Pages/Nourish/NourishMonitor';

export const ExportTester = () => {

    const [exports, setExports] = useState(false);
    const yourDataArray = [{ site: "1", room: "2", carer: "3" }, { site: "1", room: "2", carer: "3" }, { site: "1", room: "2", carer: "3" }, { site: "1", room: "2", carer: "3" }];

    return (
        <div>
            {(exports === true) && <ExportHandler title={"Test export"} processor={"livecalls"} data={yourDataArray} onClose={() => setExports(false)} show={exports} />}
            <div>This is a test page {exports.toString()}</div>
            <button onClick={() => { setExports(true) }}>Export</button>
        </div>
    )
}


const ExportHandler = ({ title, data, onClose, show, processor, masterData, options }) => {

    const { isModalOpen, openModal, closeModal } = useModal();
    const [status, setStatus] = useState("Preparing data...");

    function newWorkbook() { return XLSX.utils.book_new(); }
    function jsonToSheet(data) { return XLSX.utils.json_to_sheet(data); }
    function appendSheet(wb, ws, sheetName) { XLSX.utils.book_append_sheet(wb, ws, sheetName); }
    function writeFile(wb, filename) { XLSX.writeFile(wb, `${getFilename(filename)}.xlsx`); }

    function closeClick(){
        if (onClose) onClose();
        closeModal();
    }

    function translateCare(care) {
        if (care === undefined) return "";
        if (care === null) return "";
        if (care === "moving") return "Moving";
        if (care === "continence") return "";
        if (care === "nutandhyd") return "Nutrition and hydration";
        if (care === "social") return "Social";
        if (care === "sleep") return "Sleep";
        if (care === "personal") return "Personal";
        return care;
    }

    function displayCareDelivered(careRecs) {
        if (careRecs === null) return "";
        if (careRecs === undefined) return "";
        if (careRecs.length === 0) return "";
        try {
            if (careRecs.length < 1) return "";
            let careEntries = careRecs.map(c => translateCare((c.CallType === "Comments") ? c.CallValue : c.CallType));
            careEntries = careEntries.filter(c => c !== "");
            return careEntries = careEntries.join(", ");
        }
        catch (e) {
            return "";
        }
    }

    function getBreakdownCalls(calls) {
        let brokenDownCalls = calls.map(c =>c.calls);
        //reduce the array of arrays to a single array
        return brokenDownCalls.reduce((acc, val) => acc.concat(val), []);
    }



    function livecalls(data) {
        console.log("livecalls", data);
        setStatus("Creating export file...");

        let printViewBreakdown = null;
        let breakdownData = null;

        if (data[0].calls !== undefined)
        {   
            breakdownData = getBreakdownCalls(data);
        
            printViewBreakdown = breakdownData.map(d => {
                return {
                    "Journey Ref": d.journeyRef,
                    "Call Source": d.DeviceType,
                    "Call Types": d.callType,
                    "Room": d.room,
                    "Zone": (d.Zone)?d.Zone:d.zone,
                    "Start": displayDateTimeDisplayFromUTC(d.start),
                    "End": displayDateTimeDisplayFromUTC(d.end),
                    "Duration": d.duration,
                    "Carer": (d.Carer)?d.Carer:d.carer,
                    "Care Delivered": displayCareDelivered(d.care_delivered)
                }
            })
        }
        else
        {
            printViewBreakdown = data.map(d => {
                return {
                    "Call Source": d.DeviceType,
                    "Call Types": d.callType,
                    "Room": d.room,
                    "Zone": (d.Zone)?d.Zone:d.zone,
                    "Start": displayDateTimeDisplayFromUTC(d.start),
                    "End": displayDateTimeDisplayFromUTC(d.end),
                    "Duration": d.duration,
                    "Carer": (d.Carer)?d.Carer:d.carer,
                    "Care Delivered": displayCareDelivered(d.care_delivered)
                }
            })
        }
        
        setStatus("Your export will automatically download.  You may close this window once downloaded.");
    
        setTimeout(() => {

            const wb = newWorkbook();
            const ws = jsonToSheet(printViewBreakdown);
            appendSheet(wb, ws, "Journey Breakdown");

            const groupedCalls = groupBy(printViewBreakdown, "Call Types");
            Object.keys(groupedCalls).forEach(gc => {

                const wsgc = jsonToSheet(groupedCalls[gc]);
                appendSheet(wb, wsgc, gc);

            });

            writeFile(wb, "Report Calls");

        }, 1000);

    }


    function reportCalls(datalist) {

        setStatus("Creating export file...");

        const breakdownData = getBreakdownCalls(masterData);
        
        const printViewBreakdown = breakdownData.map(d => {
            return {
                "Journey Ref": d.journeyRef,
                "Call Source": d.DeviceType,
                "Call Types": d.callType,
                "Room": d.room,
                "Zone": (d.Zone)?d.Zone:d.zone,
                "Start": displayDateTimeDisplayFromUTC(d.start),
                "End": displayDateTimeDisplayFromUTC(d.end),
                "Duration": d.duration,
                "Carer": d.Carer,
                "Care Delivered": displayCareDelivered(d.care_delivered)
            }
        })


        setStatus("Your export will automatically download.  You may close this window once downloaded.");

        setTimeout(() => {

            const wb = newWorkbook();
            const ws = jsonToSheet([]);

            const siteName = localStorage.getItem("siteName");
            const startDate = displayDateTimeDisplayFromUTC(localStorage.getItem("startDate"));
            const endDate = displayDateTimeDisplayFromUTC(localStorage.getItem("endDate"));

            XLSX.utils.sheet_add_aoa(ws, [[`Site:`]], { origin: 'A1' });
            XLSX.utils.sheet_add_aoa(ws, [[`${siteName}`]], { origin: 'B1' });
            XLSX.utils.sheet_add_aoa(ws, [[`Date: `]], { origin: 'A2' });
            XLSX.utils.sheet_add_aoa(ws, [[`${startDate} - ${endDate}`]], { origin: 'B2' });
            XLSX.utils.sheet_add_aoa(ws, [[``]], { origin: 'A3' });
            XLSX.utils.sheet_add_aoa(ws, [Object.keys(printViewBreakdown[0])], { origin: -1 });

            printViewBreakdown.forEach(pvb => {
                XLSX.utils.sheet_add_aoa(ws, [Object.values(pvb)], { origin: -1 });
            });

            appendSheet(wb, ws, "Journey Breakdown");
            
            const groupedCalls = groupBy(printViewBreakdown, "Call Types");
            Object.keys(groupedCalls).forEach(gc => {

                const wsgc = jsonToSheet(groupedCalls[gc]);
                appendSheet(wb, wsgc, gc);

            });

            writeFile(wb, "Reports");

        }, 1000);

    }

    function residentsExport(datalist, options) {

        const printView = datalist.map(d => {
            return {
                "Title": d.title,
                "First Name": d.firstName,
                "Surname": d.surname,
                "Room": getRoomRowValue(d.RoomUnits, options).join(" - "),
                "Zone": getZoneRowValue(d.RoomUnits, options).join(" - "),
                'Status': d.status
            }
        });


        setStatus("Your export will automatically download.  You may close this window once downloaded.");

        setTimeout(() => {
            const wb = newWorkbook();
            const ws = jsonToSheet(printView);
            appendSheet(wb, ws, "Residents");
            writeFile(wb, "Residents");
        }, 1000);

    }


    function teamsExport(datalist) {

        const printView = datalist.map(d => {
            return {
                "Title": d.title,
                "First name": d.firstName,
                "Surname": d.surname,
                "Email": d.email,
                'Selected Site': d.selectedSite,
                "role": d.role,
                'Active/Inactive': (d.auth_token === undefined) ? 'Inactive' : 'Active'
            }
        })

        setStatus("Your export will automatically download.  You may close this window once downloaded.");

        setTimeout(() => {
            const wb = newWorkbook();
            const ws = jsonToSheet(printView);
            appendSheet(wb, ws, "Teams");
            writeFile(wb, "Teams");
        }, 1000);

    }


    function userManagementExport(datalist) {

        const printView = datalist.map(d => {
            return {
                "Title": d.title,
                "First name": d.firstName,
                "Surname": d.surname,
                "Email": d.email,
                "Selected Site": d.selectedSite,
                "Role": d.role,
                'Active/Inactive': (d.auth_token === undefined) ? 'Inactive' : 'Active'
            }
        })

        setStatus("Your export will automatically download.  You may close this window once downloaded.");

        setTimeout(() => {
            const wb = newWorkbook();
            const ws = jsonToSheet(printView);
            appendSheet(wb, ws, "Users");
            writeFile(wb, "User_Mangement");
        }, 1000);

    }

    function integrationsExport(datalist) {

        const printView = datalist.map(d => {
            return {
                "Intergration Type": d.integrationType,
                "Integration Key": d.integrationKey,
                "Integration Status": d.integrationEnabled,
            }
        })

        setStatus("Your export will automatically download.  You may close this window once downloaded.");

        setTimeout(() => {
            const wb = newWorkbook();
            const ws = jsonToSheet(printView);
            appendSheet(wb, ws, "Integrations");
            writeFile(wb, "Integrations");
        }, 1000);

    }

    function groupExport(datalist) {

        const printView = datalist.map(d => {
            return {
                "Device Group Name": d.devicegroupName,
                "Device Group ID": d.deviceGroupID,
                "Grouped Devices": d.groupDevices,
            }
        })

        setStatus("Your export will automatically download.  You may close this window once downloaded.");

        setTimeout(() => {
            const wb = newWorkbook();
            const ws = jsonToSheet(printView);
            appendSheet(wb, ws, "Groups");
            writeFile(wb, "Groups");
        }, 1000);

    }


    function batteryMonitorExport(datalist) {

        const printView = datalist.map(d => {
            return {
                "Unit ID": d.unitId,
                "Name": d.roomName,
                'Battery': (d.text === 'Charging') ? 'Charging' : 'Low Battery',
                // "Battery":(d.charging===1)?"Charging":(d.level===0)?"Low Battery":"",
                "Last reported": displayDateTimeFromUTCExport(d.date_time)
            }
        })


        setStatus("Your export will automatically download.  You may close this window once downloaded.");

        setTimeout(() => {
            const wb = newWorkbook();
            const ws = jsonToSheet(printView);
            appendSheet(wb, ws, "Battery");
            writeFile(wb, "Battery_Levels");
        }, 1000);

    }

    function heartBeatMonitorExport(datalist) {

        const printView = datalist.map(d => {
            return {
                'Status': d.unitStatus,
                "Unit ID": d.unitId,
                "Room": d.unitName,
                'Zone': d.unitZone,
                "Last reported": d.lastTime
            }
        })

        setStatus("Your export will automatically download.  You may close this window once downloaded.");

        setTimeout(() => {
            const wb = newWorkbook();
            const ws = jsonToSheet(printView);
            appendSheet(wb, ws, "Battery");
            writeFile(wb, "Battery_Levels");
        }, 1000);

    }

    function zoneProfilesExport(datalist) {

        let printView = []

        datalist.map(d => {
            d.zones.map(dd => {
                printView.push({
                    'Profile Zone': d.name,
                    "Zone Name": dd.name,
                    'Accessory': dd.accSelected,
                    'Assistance': dd.assSelected,
                    'Attendance': dd.attSelected,
                    'Call': dd.callSelected,
                    'Emergency': dd.emgSelected,
                })
            })
        })

        setStatus("Your export will automatically download.  You may close this window once downloaded.");

        setTimeout(() => {
            const wb = newWorkbook();
            const ws = jsonToSheet(printView);
            appendSheet(wb, ws, "Users");
            writeFile(wb, "User_Mangement");
        }, 1000);

    }

    function devicesExport(datalist) {

        const printView = datalist.map(d => {
            return {
                "Room Unit Code": d.unitId,
                "Unit Name": d.unitName,
                "Zone": d.unitZone,
            }
        })

        setStatus("Your export will automatically download.  You may close this window once downloaded.");

        setTimeout(() => {
            const wb = newWorkbook();
            const ws = jsonToSheet(printView);
            appendSheet(wb, ws, "Devices");
            writeFile(wb, "Device_List");
        }, 1000);

    }

    function getCurrentDateTimeForFilename() {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        return `${year}_${month}_${day}_${hours}_${minutes}`;
    }

    function getFilename(etype) {
        return `${etype}_${getCurrentDateTimeForFilename()}`;
    }


    useState(() => { if (show === true) openModal(); }, [title, data, show]);

    useState(() => {

        if ((Array.isArray(data)) && (data.length === 0)) {
            setStatus("No data to export.");
            return;
        }
        if ((typeof (data) === "object") && (Object.keys(data).length === 0)) {
            setStatus("No data to export.");
            return;
        }

        if (processor === "live_calls") {
            livecalls(data);
            return;
        }

        if (processor === "team_management") {
            teamsExport(data);
            return;
        }

        if (processor === "residents_list") {
            residentsExport(data, options);
            return;
        }

        if (processor === "user_management") {
            userManagementExport(data);
            return;
        }

        if (processor === "devices") {
            devicesExport(data);
            return;
        }

        if (processor === "settings_integrations") {
            integrationsExport(data);
            return;
        }

        if (processor === "settings_groups") {
            groupExport(data);
            return;
        }

        if (processor === "zone_profile") {
            zoneProfilesExport(data);
            return;
        }

        if (processor === "Battery_Monitor") {
            batteryMonitorExport(data);
            return;
        }

        if (processor === "Heartbeat_Monitor") {
            heartBeatMonitorExport(data);
            return;
        }

        if (processor === "reporting_data") {
            reportCalls(data);
            return;
        }

        setStatus("No processor has been configured for this export. Please contact Arquella Support.");

    }, [data])

    return (
        <>
            {
                isModalOpen && (
                    <div className="innerLayer" >
                        <h2>Exporting {title}</h2>
                        <div className='aqForm' style={{ height: '100px', overflowY: "scroll", color: "black" }}>
                            {status}
                            <div className='submit_fields'>
                                <div className='validate_close_div'>
                                    <button className='close_buton' onClick={closeClick} style={{ width: '70px' }}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default ExportHandler;